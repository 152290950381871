import { render, staticRenderFns } from "./ywClassMins2.vue?vue&type=template&id=5d04ff26"
import script from "./ywClassMins2.vue?vue&type=script&lang=js"
export * from "./ywClassMins2.vue?vue&type=script&lang=js"
import style0 from "./ywClassMins2.vue?vue&type=style&index=0&id=5d04ff26&prod&lang=less"
import style1 from "./ywClassMins2.vue?vue&type=style&index=1&id=5d04ff26&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports