<template>
  <div
    class="pageContol listWrap classReviewDetail"
    v-if="this.$route.query.projectSource == '30' "
  >
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级审核</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">详情</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs noStyle">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课程信息" name="first">
            <ywClassMins />
          </el-tab-pane>
          <el-tab-pane label="机构点名" name="second">
            <organizationRollCall :rollType="10" />
          </el-tab-pane>
        </el-tabs>
        <el-button @click="$router.back()" class="bgc-bv bottom-btn" size="small">返回</el-button>
      </div>
    </div>
  </div>
  <div class="pageContol classReviewDetail" v-else>
    <seeClassMinsCtr />
  </div>
</template>
<script>
import List from "@/mixins/List";
import ywClassMins from "@/views/classListCom/classDetailsFile/ywClassMins2.vue";
import seeClassMinsCtr from "@/views/classListCom/classDetailsFile/seeClassMinsCtr.vue";
import organizationRollCall from "@/views/classListCom/classDetailsFile/organizationRollCall.vue";
export default {
  name: "classdetailtabs",
  components: {
    seeClassMinsCtr,
    ywClassMins,
    organizationRollCall
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      projectName: ""
    };
  },
  methods: {
    init() {
      this.projectName = sessionStorage.getItem("projectName");
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  }
};
</script>
<style lang="less" scoped>

  .noStyle {
    position: relative;
    width: 100%;
   
    .bottom-btn {
      position: absolute;
      right:13px;
      top: 13px;
      
    }
  }


.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
.el-tab-pane {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
